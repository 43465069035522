import React, { createContext, useContext } from 'react';
import { Car } from '../../types/home'; // Ensure `Car` type is exported from CarTable

const CarContext = createContext<{ cars: Array<Car> }>({ cars: [] });

export const useCarContext = () => useContext(CarContext);

export const CarProvider: React.FC<{ cars: Array<Car>; children: React.ReactNode }> = ({ cars, children }) => {
    return <CarContext.Provider value={{ cars }}>{children}</CarContext.Provider>;
};
