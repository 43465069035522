import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Row } from 'antd';

interface TitleStatusProps {
    titleStatus: string[];
    onChange: (id: string, value: string[]) => void;
}

const titleStatuses = ['Clean', 'Other'];


export const FilterTitleStatus: React.FC<TitleStatusProps> = ({ titleStatus, onChange }) => {
    const [checkedValues, setCheckedValues] = useState<string[]>(titleStatus || []);


    useEffect(() => {
        setCheckedValues(titleStatus || []);
    }, [titleStatus]);

    const handleChange = (checkedValues: string[]) => {
        setCheckedValues(checkedValues);
        onChange('titleStatus', checkedValues);
    };

    return (
        <Checkbox.Group style={{ width: '100%' }} value={checkedValues} onChange={handleChange}>
            <Col>
                {titleStatuses && titleStatuses.length > 0 && titleStatuses.map((type) => (
                    <Row key={type} style={{ marginBottom: '10px' }}>
                        <Checkbox value={type}>{type}</Checkbox>
                    </Row>
                ))}
            </Col>
        </Checkbox.Group>
    );
};

export default FilterTitleStatus;