export interface Car {
    key: string;
    name: string;
    currentBid: string;
    timeLeft: string;
    LastUpdated: string;
    location: string;
    website: string;
    reserveStatus: string;
    image: string;
    link: string;
    AveragePrice: any;
    Favorited: boolean;
    AdditionalImages: string[];
    Make: string;
    Model: string;
    Trim: string;
    Year: string;
    Bids: string;
    Comments: string;
    Description: string;
    Drivetrain: string;
    Transmission: string;
    Engine: string;
    VIN: string;
    Mileage: string;
    bodyClass: string;
    exteriorColor: string;
    interiorColor: string;
    sellerType: string;
    titleStatus: string;
}

export interface FilterValues {
    selectedMakes: string[];
    selectedModels: string[];
    websites: string[];
    reserve: string[];
    sellerType: string[];
    titleStatus: string[];
    startYear: string;
    endYear: string;
    location: string;
    distance: string;
    // Add other filter values here
}

export const defaultFilterValues = {
    selectedMakes: [],
    selectedModels: [],
    websites: [],
    reserve: [],
    sellerType: [],
    titleStatus: [],
    startYear: '',
    endYear: '',
    location: '',
    distance: '',
    };