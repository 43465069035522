import React from 'react';
import { Input } from 'antd';
import './SearchBar.scss';

interface SearchBarProps {
    value?: string;
    onSearchTermChange: (term: string) => void;
}


const SearchBar: React.FC<SearchBarProps> = ({ value, onSearchTermChange }) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSearchTermChange(event.target.value);
    };

    return (
        <div className="search-bar">
            <Input
                placeholder="Search for car by name..."
                allowClear
                value={value} // Controlled by parent
                onChange={handleInputChange}
            />
        </div>
    );
};

export { SearchBar };
