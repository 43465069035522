import axios from 'axios'
import { SCOUT_API,PPI_API } from './constants'
import { getFirebaseAuth } from './firebase'

export const authApiAxiosWithRefresh = async () => authApiAxios((await getFirebaseAuth().currentUser?.getIdToken()) || '')

// Secure API access (with JWT tokens included)
export const authApiAxios = (token: string) => ((token: string) => axios.create({
  baseURL: SCOUT_API,
  headers: {
    Authorization: `Bearer ${token}`
  }
}))(token)

export const apiAxios = (() => axios.create({
    baseURL: SCOUT_API
  }))()

  export const authPpiApiAxiosWithRefresh = async () => authPpiApiAxios((await getFirebaseAuth().currentUser?.getIdToken()) || '')

  // Secure API access (with JWT tokens included)
  export const authPpiApiAxios = (token: string) => ((token: string) => axios.create({
    baseURL: PPI_API,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }))(token)
