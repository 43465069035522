import { apiAxios, authApiAxiosWithRefresh } from './customAxios';
import axios from 'axios';
import { formatCarData } from './formatCarData';

interface SimilarParams {
}

export const getPopularCars = async (params: SimilarParams) => {
    const data = {}
    
    try {
        const response = await (
            await authApiAxiosWithRefresh()
        ).post('/getPopularCars', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return formatCarData(response.data)
    } catch (error) {
        console.error('Error posting data:', error);
    }
};
