import { Button, Modal, Input, Form, Flex, Spin } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { useState } from 'react';
import { authPpiApiAxiosWithRefresh } from '../../utils/customAxios';

interface RepairAnalysisProps {
  car: any;
}

const RepairAnalysis: React.FC<RepairAnalysisProps> = ({ car }) => {
  // console.log(car);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<'idle' | 'success' | 'error' | 'loading'>('idle');
  const [response, setResponse] = useState<string | null>(null);
  const showModal = () => {
    setIsModalVisible(true);
    setStatus('idle'); // Reset status on opening modal
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleSubmit = async (values: { email: string }) => {
    setLoading(true);
    setStatus('loading');

    try {
      const data = {
        email: values.email,
        link: car.link,
      };
      setResponse( await (
        await authPpiApiAxiosWithRefresh()
      ).post('/CABreconditioning', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      }))
      // console.log(response);
      setStatus('success');
      form.resetFields();
    } catch (error) {
      console.error('Error submitting repair request:', error);
      setStatus('error');
      // console.log(response);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <>
      <Button onClick={showModal}>Repair Analysis</Button>
      <Modal title={car.name} open={isModalVisible} onCancel={handleCancel} footer={null} width="fit-content">
        <Form form={form} onFinish={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
            style={{ width: '90%', margin: '0 0 1rem 1rem' }}
          >
            <Input placeholder="Enter your email address" />
          </Form.Item>

          <Form.Item className="form-button" style={{ width: '90%', margin: '0 0 1rem 1rem' }}>
            <Button type="primary" htmlType="submit" disabled={loading}>
              Request AI Repair Analysis
            </Button>
          </Form.Item>
          {
            status !== 'idle' ? (
              <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem 0 3rem 0' }}>
                {loading && <Spin />}
                {status === 'success' && <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 24 }} />}
                {status === 'error' && <span><CloseCircleTwoTone twoToneColor="#ff4d4f" style={{ fontSize: 24 }} /></span>}
              </div>
            ) : null
          }
        </Form>
      </Modal>
    </>
  );
};

export default RepairAnalysis;
