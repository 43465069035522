import React, {useState} from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import './SimilarCarsSection.scss';
import { Car } from '../../../types/home';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'antd';

interface SimilarLiveCarsProps {
    similarCars: Car[];
    isMobile: boolean;
}

const SimilarLiveCars: React.FC<SimilarLiveCarsProps> = ({ similarCars, isMobile }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();

    if (!similarCars || similarCars.length === 0) {
        return null;
    }
    
    return (
        
        <div className={`similar-cars-section ${isMobile ? 'mobile' : ''}`}>
            <div className="section-header" onClick={() => setIsExpanded(!isExpanded)}>
                <h2>Similar Listings</h2>
                {isExpanded ? <DownOutlined /> : <UpOutlined />}
            </div>
            {isExpanded && (
            <>
            
            <div className={`similar-cars-grid ${isMobile ? 'mobile' : ''}`}>

                {similarCars.map((similarCar, index) => (
                    <div 
                        key={index} 
                        className="similar-car-card"
                        onClick={() => {
                            const encodedKey = encodeURIComponent(similarCar.link);
                            navigate(`/car/${encodedKey}`, { state: { car: similarCar } });
                        }}
                    >
                        <img 
                            src={similarCar.image} 
                            alt={similarCar.name}
                            className="similar-car-image"
                        />
                        <div className="similar-car-info">
                            <div className="name">{similarCar.name}</div>
                            <div className="tags-row">
                                <Tag color="blue">{similarCar.website}</Tag>
                                {similarCar.reserveStatus === 'No Reserve' && (
                                    <Tag color="yellow">No Reserve</Tag>
                                )}
                            </div>
                            <div className="price">{similarCar.currentBid.toLocaleString()}</div>
                            <div className="bids">
                                {similarCar.Bids} {similarCar.Bids !== 'No Bids' && 'bids'}
                            </div>
                            <div className="mileage">{similarCar.Mileage}</div>
                            <div className="location">
                                Ending {new Date(Number(similarCar.timeLeft)*1000).toLocaleDateString('en-US', { 
                                    month: 'short', 
                                    day: 'numeric', 
                                    year: 'numeric' 
                                })}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            </>
            )}
        </div>
            
    );
};

export default SimilarLiveCars;
