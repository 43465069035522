// CarCards.tsx
import React, { useState } from 'react';
import './CarCards.scss';
import Countdown from '../Countdown';
import { Pagination } from 'antd';
import { UpOutlined, DownOutlined, StarOutlined, StarFilled } from "@ant-design/icons"; 
import { toggleFavorite } from '../../utils/toggleFavorites';
import { Car } from '../../types/home';
import { useNavigate } from 'react-router-dom';

interface CarCardProps {
    page: number;
    itemsPerPage: number;
    dataSource: Array<Car>;
    carsLength: number;
    onPageChange: (page: number) => void;
    setDataSource: (cars: Array<Car>) => void;
    starToggle: string;
    setStarChanged: (starChanged: boolean) => void;
    onNavigateToDetails: () => void;
    pages: boolean; // Determines if pagination should be shown
}

const CarCards: React.FC<CarCardProps> = ({ 
    page, 
    itemsPerPage, 
    dataSource, 
    carsLength, 
    onPageChange, 
    setDataSource, 
    starToggle, 
    setStarChanged, 
    onNavigateToDetails,
    pages // Destructure the 'pages' prop
}) => {
    const [currentPage, setCurrentPage] = useState(page);
    const navigate = useNavigate();

    const handleTableChange = (pagination: number) => { // Changed type to number for clarity
        setCurrentPage(pagination);
        onPageChange(pagination); // Notify parent component of page change
    };

    const handleViewClick = (car: Car) => {
        onNavigateToDetails();
        const encodedKey = encodeURIComponent(car.key); // Encode the key
        navigate(`/car/${encodedKey}`, { state: { car: car } });
    };

    const toggleStar = (name: string) => {
        const updatedDataSource = dataSource.map((car) => {
            if (car.name === name) {
                toggleFavorite({ link: car.link, favorited: !car.Favorited });
                return { ...car, Favorited: !car.Favorited }; // Toggle Favorited
            }
            return car;
        });
        if (starToggle === "starred") {
            setStarChanged(true);
        }
        setDataSource(updatedDataSource); // Update the dataSource state
    };

    const CarTags: React.FC<{ car: Car }> = ({ car }) => { // Changed 'any' to 'Car' for type safety
        // Utility functions
        const isNumeric = (value: any) => {
            if (typeof value === 'string') {
                value = value.replace(/[^0-9.-]+/g, ''); // Remove non-numeric characters
            }
            return !isNaN(parseFloat(value)) && isFinite(value);
        };
        
        const cleanNumericValue = (value: string) => parseFloat(value.replace(/[^0-9.-]+/g, ''));
        
        // Calculations
        const currentBidValue = isNumeric(car.currentBid) ? cleanNumericValue(car.currentBid) : null;
        const averagePriceValue = isNumeric(car.AveragePrice) ? cleanNumericValue(car.AveragePrice) : null;
        const isBidHigher = currentBidValue !== null && averagePriceValue !== null && currentBidValue > averagePriceValue;
        const isInvalid = currentBidValue === null || averagePriceValue === null;
        const difference = (averagePriceValue && currentBidValue) ? averagePriceValue - currentBidValue : null;
        const formattedDifference = difference !== null 
            ? `$${difference.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}` 
            : null;

        return (
            <div className="car-tags">
                {car.reserveStatus === "No Reserve" && (
                    <span className="car-tag reserve-status no-reserve">
                        {car.reserveStatus}
                    </span>
                )}
                <span className="car-tag current-bid">
                    Bid: {car.currentBid}
                </span>
                {!isInvalid && (
                    <span className={`car-tag average-price ${isBidHigher ? 'higher-bid' : 'lower-bid'}`}>
                        {isBidHigher ? <DownOutlined className='arrow-down' /> : <UpOutlined className='arrow-up' />}
                        Avg Retail: {car.AveragePrice}
                    </span>
                )}
                {!isInvalid && (
                    <span className={`car-tag difference ${isBidHigher ? 'higher-bid' : 'lower-bid'}`}>
                        Margin: {formattedDifference}
                    </span>
                )}
            </div>
        );
    };

    return (
        <div className="car-cards-container">
            {dataSource.map((car) => (
                <div className="car-card" key={car.key}>
                    <div className="image-container">
                        <img src={car.image} alt={car.name} />
                        <div className="time-left">
                            <Countdown unixTimestamp={car.timeLeft} />
                        </div>
                    </div>
                    <div className="car-details">
                        <div className="name-row">
                            <h3 className="car-name">{car.name}</h3>
                            <span className="website-name">{car.website}</span>
                        </div>
                        <CarTags car={car} />
                        <p className="car-location">Location: {car.location}</p>
                        <div className="container">
                            <span
                                className="star-icon"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleStar(car.name);
                                }}
                            >
                                {car.Favorited ? (
                                    <StarFilled style={{ color: 'gold' }} />
                                ) : (
                                    <StarOutlined />
                                )}
                            </span>
                            <button
                                className="view-button"
                                onClick={() => handleViewClick(car)}
                            >
                                View Details
                            </button>
                        </div>
                    </div>
                </div>
            ))}
            
            {/* Conditionally render Pagination if 'pages' is true */}
            {pages && (
                <Pagination
                    className="custom-pagination"
                    current={page}
                    pageSize={itemsPerPage}
                    total={carsLength}
                    onChange={handleTableChange}
                    showSizeChanger={false}
                />
            )}
        </div>
    );
};

export default CarCards;
