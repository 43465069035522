import React, { useEffect, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../utils/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../../components/TopBar/TopBar";
import { useUser } from "../../context/UserContext";
import './Signin.scss';
import { apiAxios } from "../../utils/customAxios";

const Signin: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setEmail } = useUser();
  const [userVar, setUser] = useState<any>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        const redirectTo = new URLSearchParams(location.search).get("redirectTo") || "/landing";
        navigate(redirectTo + location.search); // Preserve query parameters
      }
    });
    return unsubscribe;
  }, [location.search, navigate]);

  const [email, setEmailState] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  
  useEffect(() => {
    console.log("User email in Account component:", email);
  }, [email]);

  const handleSignin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const token = await userCredential.user?.getIdToken();
      await apiAxios.post("/signin", { id_token: token });
      setEmail(email); // Set the email in context

      const redirectTo = new URLSearchParams(location.search).get("redirectTo") || "/";
      navigate(redirectTo + location.search); // Preserve query parameters
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <div className="page-container">
      <TopBar className="title" title="Sign In"/>
      <div className="signin-container">
        <form onSubmit={handleSignin}>
          <h2>SIGN IN</h2>
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmailState(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <button type="submit">Sign In</button>
  
          <div className="divider">
            <span>OR</span>
          </div>
  
          <div className="social-icons">
            {/* Social login options */}
          </div>
  
          <div className="signup-link">
            Need an Account? <a target="_blank" href="https://app.pacer.co/#/sign-up">SIGN UP FOR PACER</a>
          </div>
        </form>
      </div>
    </div>
  );  
};

export default Signin;
